import {
    GetOldDatumHourlyQuery,
    ListOldDataHourlyByDatumIdQuery,
    ListOldDataHourlyByDatumIdQueryVariables,
    ListOldDataQuery,
    ListOldDataQueryVariables,
    OnCreateOldDatumHourlySubscription,
    OnDeleteOldDatumHourlySubscription,
    OnUpdateOldDatumHourlySubscription,
} from "../../API";
import {
    getOldDatumHourly,
    listOldData,
    listOldDataHourlyByDatumId,
} from "../../graphql/queries";
import {
    onDeleteOldDatumHourly,
    onUpdateOldDatumHourly,
} from "../../graphql/subscriptions";
import { Cache } from "./lib/cache";
import { CachedTable } from "./lib/cached-table";
import { GraphQLGetItem, GraphQLQuery, GraphQLSubscribe } from "./lib/graphql";

export type OldDatumHourly = Exclude<
    GetOldDatumHourlyQuery["getOldDatumHourly"],
    null | undefined
>;

export class OldDatumHourlyTable extends CachedTable<
    // レコードの型
    OldDatumHourly,
    // インデックスのリスト
    {
        listOldData: ListOldDataQueryVariables;
        listOldDataHourlyByDatumId: ListOldDataHourlyByDatumIdQueryVariables;
    },
    // プライマリ・インデックス
    "listOldData"
> {
    constructor(cache: Cache) {
        super(cache, {
            getItem: new GraphQLGetItem<
                GetOldDatumHourlyQuery,
                "getOldDatumHourly",
                OldDatumHourly
            >(getOldDatumHourly, "getOldDatumHourly", convertItem),

            query: new GraphQLQuery<
                // インデックスのリスト
                {
                    listOldData: [ListOldDataQueryVariables, ListOldDataQuery];
                    listOldDataHourlyByDatumId: [
                        ListOldDataHourlyByDatumIdQueryVariables,
                        ListOldDataHourlyByDatumIdQuery,
                    ];
                },
                // プライマリ・インデックス
                "listOldData",
                OldDatumHourly
            >(
                { listOldData, listOldDataHourlyByDatumId },
                "listOldData",
                convertItem,
            ),

            subscription: new GraphQLSubscribe<
                | OnCreateOldDatumHourlySubscription
                | OnDeleteOldDatumHourlySubscription
                | OnUpdateOldDatumHourlySubscription
            >([onDeleteOldDatumHourly, onUpdateOldDatumHourly]),
        });
    }
}

function convertItem(item: OldDatumHourly): OldDatumHourly {
    return item;
}
