import { invokeGraphQL } from "./misc";

/**
 * GraphQL API の `Query` リゾルバを叩く処理をカプセル化するクラスです。
 * @see https://docs.aws.amazon.com/ja_jp/appsync/latest/devguide/resolver-mapping-template-reference-dynamodb.html#aws-appsync-resolver-mapping-template-reference-dynamodb-query
 */

/**
 * Query の limit フィールドの初期値
 */
const DEFAULT_LIMIT: number | null = 1000; // List size cannot exceed 1000

export class GraphQLQuery<
    TIndexMap extends Record<
        string,
        [GraphQLQuery.InputBase, GraphQLQuery.OutputBase]
    >,
    TPrimaryIndex extends keyof TIndexMap,
    TRecord,
> {
    /** GraphQL クエリ文字列 */
    readonly querys: Record<keyof TIndexMap, string>;
    /** 結果値が格納されるプロパティのプロパティ名 */
    readonly primaryIndex: TPrimaryIndex;
    /** 結果値を変換する関数 */
    readonly convertItem: GraphQLQuery.ConvertFunction<TIndexMap, TRecord>;

    constructor(
        querys: Record<keyof TIndexMap, string>,
        primaryIndex: TPrimaryIndex,
        convertItem: GraphQLQuery.ConvertFunction<TIndexMap, TRecord>,
    ) {
        this.querys = querys;
        this.primaryIndex = primaryIndex;
        this.convertItem = convertItem;
    }

    /**
     * GraphQL API を叩いて、検索条件にマッチするレコードを取得します。
     * @param index インデックス名 (クエリ名)
     * @param input 検索条件
     * @param signal 中止シグナル
     */
    async invoke(
        input: GraphQLQuery.Input<TIndexMap, TPrimaryIndex> | undefined,
        signal: AbortSignal | undefined,
    ): Promise<{ items: TRecord[]; nextToken: string | undefined }> {
        const { convertItem, primaryIndex, querys } = this;
        const { index = primaryIndex, ...rest } = input ?? {};
        const query = querys[index];
        const output = (await invokeGraphQL(
            query,
            {
                ...rest,
                limit:
                    (rest as { limit?: number | null }).limit ?? DEFAULT_LIMIT,
            } as Record<string, unknown>,
            signal,
        )) as Record<keyof TIndexMap, GraphQLQuery.ItemsAndNextToken>;
        const list = output[index];

        if (list == null || !Array.isArray(list.items)) {
            console.error(
                "Unexpected non-array value on the list field: Index=%o, Output=%o",
                index,
                output,
            );
            throw new Error("Unexpected non-array value on the list field.");
        }

        return {
            items: list.items.map(convertItem),
            nextToken: list.nextToken ?? undefined,
        };
    }
}
export namespace GraphQLQuery {
    /**
     * 検索条件のテンプレート。
     */
    export type InputBase = {
        filter?: Record<string, unknown> | null;
        limit?: number | null;
        nextToken?: string | null;
    };

    /**
     * 結果値のテンプレート
     */
    export type OutputBase = {
        [dataField: string]: ItemsAndNextToken | null | undefined;
    };

    /**
     * 結果の配列と `nextToken` のペア
     */
    export type ItemsAndNextToken = {
        items?: any[] | null | undefined; // eslint-disable-line @typescript-eslint/no-explicit-any
        nextToken?: string | null | undefined;
    };

    export type Input<
        TIndexMap extends Record<
            string,
            [GraphQLQuery.InputBase, GraphQLQuery.OutputBase]
        >,
        TPrimaryIndex extends keyof TIndexMap,
    > =
        | ({ index?: undefined } & TIndexMap[TPrimaryIndex][0])
        | {
              [P in keyof TIndexMap]: { index: P } & TIndexMap[P][0];
          }[keyof TIndexMap];

    /**
     * 変換関数の型。
     */
    export type ConvertFunction<
        TIndexMap extends Record<
            string,
            [GraphQLQuery.InputBase, GraphQLQuery.OutputBase]
        >,
        TRecord,
    > = (
        item: {
            [P in keyof TIndexMap]: TIndexMap[P][1] extends Record<P, infer U>
                ? U extends { items: readonly (infer E)[] | null }
                    ? Exclude<E, null | undefined>
                    : never
                : never;
        }[keyof TIndexMap],
    ) => TRecord;
}
