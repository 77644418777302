import {
    GetOperatingRatioQuery,
    ListOperatingRatioByNodeIdQuery,
    ListOperatingRatioByNodeIdQueryVariables,
    ListOperatingRatiosQuery,
    ListOperatingRatiosQueryVariables,
    OnCreateOperatingRatioSubscription,
    OnDeleteOperatingRatioSubscription,
    OnUpdateOperatingRatioSubscription,
} from "../../API";
import {
    getOperatingRatio,
    listOperatingRatioByNodeId,
    listOperatingRatios,
} from "../../graphql/queries";
import {
    onCreateOperatingRatio,
    onDeleteOperatingRatio,
    onUpdateOperatingRatio,
} from "../../graphql/subscriptions";
import { Cache } from "./lib/cache";
import { CachedTable } from "./lib/cached-table";
import { GraphQLGetItem, GraphQLQuery, GraphQLSubscribe } from "./lib/graphql";

export type OperatingRatio = Exclude<
    GetOperatingRatioQuery["getOperatingRatio"],
    null | undefined
>;

export class OperatingRatioTable extends CachedTable<
    // レコードの型
    OperatingRatio,
    // インデックスのリスト
    {
        listOperatingRatios: ListOperatingRatiosQueryVariables;
        listOperatingRatioByNodeId: ListOperatingRatioByNodeIdQueryVariables;
    },
    // プライマリ・インデックス
    "listOperatingRatios"
> {
    constructor(cache: Cache) {
        super(cache, {
            getItem: new GraphQLGetItem<
                GetOperatingRatioQuery,
                "getOperatingRatio",
                OperatingRatio
            >(getOperatingRatio, "getOperatingRatio", convertItem),

            query: new GraphQLQuery<
                // インデックスのリスト
                {
                    listOperatingRatios: [
                        ListOperatingRatiosQueryVariables,
                        ListOperatingRatiosQuery,
                    ];
                    listOperatingRatioByNodeId: [
                        ListOperatingRatioByNodeIdQueryVariables,
                        ListOperatingRatioByNodeIdQuery,
                    ];
                },
                // プライマリ・インデックス
                "listOperatingRatios",
                OperatingRatio
            >(
                { listOperatingRatios, listOperatingRatioByNodeId },
                "listOperatingRatios",
                convertItem,
            ),

            subscription: new GraphQLSubscribe<
                | OnCreateOperatingRatioSubscription
                | OnDeleteOperatingRatioSubscription
                | OnUpdateOperatingRatioSubscription
            >([
                onCreateOperatingRatio,
                onDeleteOperatingRatio,
                onUpdateOperatingRatio,
            ]),
        });
    }
}

function convertItem(item: OperatingRatio): OperatingRatio {
    return item;
}
