import API, { graphqlOperation } from "@aws-amplify/api-graphql";
import { Observable, ZenObservable } from "zen-observable-ts";

/**
 * AppSync の `Subscription` を扱うクラスです。
 * @see https://docs.aws.amazon.com/ja_jp/appsync/latest/devguide/real-time-data.html
 */
export class GraphQLSubscribe<TOutput> {
    /** 購読の GraphQL クエリ文字列リスト */
    readonly subscriptions: readonly string[];

    constructor(subscriptions: readonly string[]) {
        this.subscriptions = subscriptions;
    }

    /**
     * 購読を開始します。
     * 戻り値の `.unsubscribe()` メソッドを呼ぶことで、購読を停止できます。
     * @param onChange 変更検出時に呼ばれるコールバック関数
     * @param onError エラー発生時に呼ばれるコールバック関数
     */
    subscribe(
        onChange: (data: TOutput) => void,
        onError: (error: SubscribeError) => void,
    ): ZenObservable.Subscription {
        function next(data: SubscribeEventData<TOutput>) {
            onChange(data.value.data);
        }

        const subscriptions = this.subscriptions.map((subscription) =>
            (
                API.graphql(graphqlOperation(subscription)) as Observable<
                    SubscribeEventData<TOutput>
                >
            ).subscribe(next, onError),
        );

        return {
            get closed(): boolean {
                return subscriptions.every((s) => s.closed);
            },
            unsubscribe(): void {
                for (const s of subscriptions) {
                    s.unsubscribe();
                }
            },
        };
    }
}

export type SubscribeEventData<T> = {
    provider: unknown;
    value: { data: T };
};

export type SubscribeError = {
    provider: unknown;
    error: unknown;
};
