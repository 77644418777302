import {
    GetAgvPositionQuery,
    ListAgvPositionByNodeIdQuery,
    ListAgvPositionByNodeIdQueryVariables,
    ListAgvPositionsQuery,
    ListAgvPositionsQueryVariables,
    OnCreateAgvPositionSubscription,
    OnDeleteAgvPositionSubscription,
    OnUpdateAgvPositionSubscription,
} from "../../API";
import {
    getAgvPosition,
    listAgvPositionByNodeId,
    listAgvPositions,
} from "../../graphql/queries";
import {
    onCreateAgvPosition,
    onDeleteAgvPosition,
    onUpdateAgvPosition,
} from "../../graphql/subscriptions";
import { Cache } from "./lib/cache";
import { CachedTable } from "./lib/cached-table";
import { GraphQLGetItem, GraphQLQuery, GraphQLSubscribe } from "./lib/graphql";

export type AgvPosition = Exclude<
    GetAgvPositionQuery["getAgvPosition"],
    null | undefined
>;

export class AgvPositionTable extends CachedTable<
    // レコードの型
    AgvPosition,
    // インデックスのリスト
    {
        listAgvPositions: ListAgvPositionsQueryVariables;
        listAgvPositionByNodeId: ListAgvPositionByNodeIdQueryVariables;
    },
    // プライマリ・インデックス
    "listAgvPositions"
> {
    constructor(cache: Cache) {
        super(cache, {
            getItem: new GraphQLGetItem<
                GetAgvPositionQuery,
                "getAgvPosition",
                AgvPosition
            >(getAgvPosition, "getAgvPosition", convertItem),

            query: new GraphQLQuery<
                // インデックスのリスト
                {
                    listAgvPositions: [
                        ListAgvPositionsQueryVariables,
                        ListAgvPositionsQuery,
                    ];
                    listAgvPositionByNodeId: [
                        ListAgvPositionByNodeIdQueryVariables,
                        ListAgvPositionByNodeIdQuery,
                    ];
                },
                // プライマリ・インデックス
                "listAgvPositions",
                AgvPosition
            >(
                { listAgvPositions, listAgvPositionByNodeId },
                "listAgvPositions",
                convertItem,
            ),

            subscription: new GraphQLSubscribe<
                | OnCreateAgvPositionSubscription
                | OnDeleteAgvPositionSubscription
                | OnUpdateAgvPositionSubscription
            >([onCreateAgvPosition, onDeleteAgvPosition, onUpdateAgvPosition]),
        });
    }
}

function convertItem(item: AgvPosition): AgvPosition {
    return item;
}
