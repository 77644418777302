import {
    GetWorkHistoryQuery,
    ListWorkHistoriesQuery,
    ListWorkHistoriesQueryVariables,
    ListWorkHistoryByNodeIdAndTimeQuery,
    ListWorkHistoryByNodeIdAndTimeQueryVariables,
    ListWorkHistoryByNodeIdQuery,
    ListWorkHistoryByNodeIdQueryVariables,
    OnCreateWorkHistorySubscription,
    OnDeleteWorkHistorySubscription,
    OnUpdateWorkHistorySubscription,
} from "../../API";
import {
    getWorkHistory,
    listWorkHistories,
    listWorkHistoryByNodeId,
    listWorkHistoryByNodeIdAndTime,
} from "../../graphql/queries";
import {
    onCreateWorkHistory,
    onDeleteWorkHistory,
    onUpdateWorkHistory,
} from "../../graphql/subscriptions";
import { Cache } from "./lib/cache";
import { CachedTable } from "./lib/cached-table";
import { GraphQLGetItem, GraphQLQuery, GraphQLSubscribe } from "./lib/graphql";

export type WorkHistory = Exclude<
    GetWorkHistoryQuery["getWorkHistory"],
    null | undefined
>;

export class WorkHistoryTable extends CachedTable<
    // レコードの型
    WorkHistory,
    // インデックスのリスト
    {
        listWorkHistories: ListWorkHistoriesQueryVariables;
        listWorkHistoryByNodeId: ListWorkHistoryByNodeIdQueryVariables;
        listWorkHistoryByNodeIdAndTime: ListWorkHistoryByNodeIdAndTimeQueryVariables;
    },
    // プライマリ・インデックス
    "listWorkHistories"
> {
    constructor(cache: Cache) {
        super(cache, {
            getItem: new GraphQLGetItem<
                GetWorkHistoryQuery,
                "getWorkHistory",
                WorkHistory
            >(getWorkHistory, "getWorkHistory", convertItem),

            query: new GraphQLQuery<
                // インデックスのリスト
                {
                    listWorkHistories: [
                        ListWorkHistoriesQueryVariables,
                        ListWorkHistoriesQuery,
                    ];
                    listWorkHistoryByNodeId: [
                        ListWorkHistoryByNodeIdQueryVariables,
                        ListWorkHistoryByNodeIdQuery,
                    ];
                    listWorkHistoryByNodeIdAndTime: [
                        ListWorkHistoryByNodeIdAndTimeQueryVariables,
                        ListWorkHistoryByNodeIdAndTimeQuery,
                    ];
                },
                // プライマリ・インデックス
                "listWorkHistories",
                WorkHistory
            >(
                {
                    listWorkHistories,
                    listWorkHistoryByNodeId,
                    listWorkHistoryByNodeIdAndTime,
                },
                "listWorkHistories",
                convertItem,
            ),

            subscription: new GraphQLSubscribe<
                | OnCreateWorkHistorySubscription
                | OnDeleteWorkHistorySubscription
                | OnUpdateWorkHistorySubscription
            >([onCreateWorkHistory, onDeleteWorkHistory, onUpdateWorkHistory]),
        });
    }
}

function convertItem(item: WorkHistory): WorkHistory {
    return item;
}
