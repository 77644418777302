import { AgvPositionTable } from "./agv-position-table";
import { DatumTable } from "./datum-table";
import { DatumTypeTable } from "./datum-type-table";
import { EccMenuTable } from "./ecc-menu-table";
import { EventlogTable } from "./eventlog-table";
import { Cache } from "./lib/cache";
import { NodeTable } from "./node-table";
import { NodeTypeTable } from "./node-type-table";
import { OldDatumDailyTable } from "./old-datum-daily-table";
import { OldDatumHourlyTable } from "./old-datum-hourly-table";
import { OldDatumTable } from "./old-datum-table";
import { OperatingRatioTable } from "./operating-ratio-table";
import { TenantTable } from "./tenant-table";
import { WorkHistoryTable } from "./work-history-table";

const instances = new Set<Database>();

export class Database {
    private readonly uuid = crypto.randomUUID();
    private readonly cache = new Cache();
    readonly nodes = new NodeTable(this.cache);
    readonly nodeTypes = new NodeTypeTable(this.cache);
    readonly datums = new DatumTable(this.cache);
    readonly datumTypes = new DatumTypeTable(this.cache);
    readonly eventlogs = new EventlogTable(this.cache);
    readonly oldData = new OldDatumTable(this.cache);
    readonly oldDataHourly = new OldDatumHourlyTable(this.cache);
    readonly oldDataDaily = new OldDatumDailyTable(this.cache);
    readonly eccMenus = new EccMenuTable(this.cache);
    readonly operatingRatios = new OperatingRatioTable(this.cache);
    readonly workHistory = new WorkHistoryTable(this.cache);
    readonly tenants = new TenantTable(this.cache);
    readonly agvPositions = new AgvPositionTable(this.cache);

    constructor() {
        // eslint-disable-next-line no-console -- デフォルト非表示のログを出したい
        console.debug(`Database<${this.uuid}>.new()`);

        if (instances.size > 0) {
            console.warn(
                "破棄されていない Database インスタンスが検出されました: %o",
                [...instances],
            );
        }

        instances.add(this);
    }

    dispose(): void {
        instances.delete(this);

        // eslint-disable-next-line no-console -- デフォルト非表示のログを出したい
        console.debug(`Database<${this.uuid}>.dispose()`);

        this.nodes.dispose();
        this.nodeTypes.dispose();
        this.datums.dispose();
        this.datumTypes.dispose();
        this.eventlogs.dispose();
        this.oldData.dispose();
        this.oldDataHourly.dispose();
        this.oldDataDaily.dispose();
        this.eccMenus.dispose();
        this.operatingRatios.dispose();
        this.workHistory.dispose();
        this.tenants.dispose();
        this.agvPositions.dispose();
    }
}
