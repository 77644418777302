import {
    GetEventlogQuery,
    ListEventlogsByDatumIdQuery,
    ListEventlogsByDatumIdQueryVariables,
    ListEventlogsByNodeIdQuery,
    ListEventlogsByNodeIdQueryVariables,
    ListEventlogsQuery,
    ListEventlogsQueryVariables,
    OnCreateEventlogSubscription,
    OnDeleteEventlogSubscription,
    OnUpdateEventlogSubscription,
} from "../../API";
import {
    getEventlog,
    listEventlogs,
    listEventlogsByDatumId,
    listEventlogsByNodeId,
} from "../../graphql/queries";
import {
    onCreateEventlog,
    onDeleteEventlog,
    onUpdateEventlog,
} from "../../graphql/subscriptions";
import { Cache } from "./lib/cache";
import { CachedTable } from "./lib/cached-table";
import { GraphQLGetItem, GraphQLQuery, GraphQLSubscribe } from "./lib/graphql";

export type Eventlog = Exclude<
    GetEventlogQuery["getEventlog"],
    null | undefined
>;

export class EventlogTable extends CachedTable<
    // レコードの型
    Eventlog,
    // インデックスのリスト
    {
        listEventlogs: ListEventlogsQueryVariables;
        listEventlogsByNodeId: ListEventlogsByNodeIdQueryVariables;
        listEventlogsByDatumId: ListEventlogsByDatumIdQueryVariables;
    },
    // プライマリ・インデックス
    "listEventlogs"
> {
    constructor(cache: Cache) {
        super(cache, {
            getItem: new GraphQLGetItem<
                GetEventlogQuery,
                "getEventlog",
                Eventlog
            >(getEventlog, "getEventlog", convertItem),

            query: new GraphQLQuery<
                // インデックスのリスト
                {
                    listEventlogs: [
                        ListEventlogsQueryVariables,
                        ListEventlogsQuery,
                    ];
                    listEventlogsByNodeId: [
                        ListEventlogsByNodeIdQueryVariables,
                        ListEventlogsByNodeIdQuery,
                    ];
                    listEventlogsByDatumId: [
                        ListEventlogsByDatumIdQueryVariables,
                        ListEventlogsByDatumIdQuery,
                    ];
                },
                // プライマリ・インデックス
                "listEventlogs",
                Eventlog
            >(
                {
                    listEventlogs,
                    listEventlogsByNodeId,
                    listEventlogsByDatumId,
                },
                "listEventlogs",
                convertItem,
            ),

            subscription: new GraphQLSubscribe<
                | OnCreateEventlogSubscription
                | OnDeleteEventlogSubscription
                | OnUpdateEventlogSubscription
            >([onCreateEventlog, onDeleteEventlog, onUpdateEventlog]),
        });
    }
}

function convertItem(item: Eventlog): Eventlog {
    return item;
}
