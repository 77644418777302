import {
    GetNodeTypeQuery,
    ListNodeTypesQuery,
    ListNodeTypesQueryVariables,
    OnCreateNodeTypeSubscription,
    OnDeleteNodeTypeSubscription,
    OnUpdateNodeTypeSubscription,
} from "../../API";
import { getNodeType, listNodeTypes } from "../../graphql/queries";
import {
    onCreateNodeType,
    onDeleteNodeType,
    onUpdateNodeType,
} from "../../graphql/subscriptions";
import { Cache } from "./lib/cache";
import { CachedTable } from "./lib/cached-table";
import { GraphQLGetItem, GraphQLQuery, GraphQLSubscribe } from "./lib/graphql";

export type NodeType = Exclude<
    GetNodeTypeQuery["getNodeType"],
    null | undefined
>;

export class NodeTypeTable extends CachedTable<
    // レコードの型
    NodeType,
    // インデックスのリスト
    {
        listNodeTypes: ListNodeTypesQueryVariables;
    },
    // プライマリ・インデックス
    "listNodeTypes"
> {
    constructor(cache: Cache) {
        super(cache, {
            getItem: new GraphQLGetItem<
                GetNodeTypeQuery,
                "getNodeType",
                NodeType
            >(getNodeType, "getNodeType", convertItem),

            query: new GraphQLQuery<
                // インデックスのリスト
                {
                    listNodeTypes: [
                        ListNodeTypesQueryVariables,
                        ListNodeTypesQuery,
                    ];
                },
                // プライマリ・インデックス
                "listNodeTypes",
                NodeType
            >({ listNodeTypes }, "listNodeTypes", convertItem),

            subscription: new GraphQLSubscribe<
                | OnCreateNodeTypeSubscription
                | OnDeleteNodeTypeSubscription
                | OnUpdateNodeTypeSubscription
            >([onCreateNodeType, onDeleteNodeType, onUpdateNodeType]),
        });
    }
}

function convertItem(item: NodeType): NodeType {
    return item;
}
