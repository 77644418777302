import {
    GetDatumQuery,
    ListDataQuery,
    ListDataQueryVariables,
    ListDatumsByNodeIdTypeIdQuery,
    ListDatumsByNodeIdTypeIdQueryVariables,
    OnCreateDatumSubscription,
    OnDeleteDatumSubscription,
    OnUpdateDatumSubscription,
} from "../../API";
import {
    getDatum,
    listData,
    listDatumsByNodeIdTypeId,
} from "../../graphql/queries";
import { onCreateDatum, onDeleteDatum } from "../../graphql/subscriptions";
import { Cache } from "./lib/cache";
import { CachedTable } from "./lib/cached-table";
import { GraphQLGetItem, GraphQLQuery, GraphQLSubscribe } from "./lib/graphql";
export type Datum = Exclude<GetDatumQuery["getDatum"], null | undefined>;

export class DatumTable extends CachedTable<
    // レコードの型
    Datum,
    // インデックスのリスト
    {
        listData: ListDataQueryVariables;
        listDatumsByNodeIdTypeId: ListDatumsByNodeIdTypeIdQueryVariables;
    },
    // プライマリ・インデックス
    "listData"
> {
    constructor(cache: Cache) {
        super(cache, {
            getItem: new GraphQLGetItem<GetDatumQuery, "getDatum", Datum>(
                getDatum,
                "getDatum",
                convertItem,
            ),

            query: new GraphQLQuery<
                // インデックスのリスト
                {
                    listData: [ListDataQueryVariables, ListDataQuery];
                    listDatumsByNodeIdTypeId: [
                        ListDatumsByNodeIdTypeIdQueryVariables,
                        ListDatumsByNodeIdTypeIdQuery,
                    ];
                },
                // プライマリ・インデックス
                "listData",
                Datum
            >(
                {
                    listData,
                    listDatumsByNodeIdTypeId,
                },
                "listData",
                convertItem,
            ),

            subscription: new GraphQLSubscribe<
                | OnCreateDatumSubscription
                | OnDeleteDatumSubscription
                | OnUpdateDatumSubscription
            >([onCreateDatum, onDeleteDatum]),
        });
    }
}

function convertItem(item: Datum): Datum {
    return item;
}
