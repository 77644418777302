import type { Table } from "./table";

export async function findGreedily<
    TInput extends
        | Table.FindParams<Record<string, Table.QueryBase>, string>
        | undefined,
    TRecord extends Table.RecordBase,
>(
    input: TInput,
    options: Table.FindOptions | undefined,
    find: (
        input: TInput,
        options: Table.FindOptions | undefined,
    ) => Promise<Table.FindResult<TRecord>>,
): Promise<readonly TRecord[]> {
    const items: TRecord[] = [];
    let nextToken: string | undefined;

    do {
        const retv = await find({ ...input, nextToken }, options);
        items.push(...retv.items);
        nextToken = retv.nextToken;
    } while (nextToken != null);

    return items;
}
