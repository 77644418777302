export default {
    // Amplify のバグのため、メールアドレスを入力した状態でパスワード未入力の場合は、このメッセージが使用される。
    // 今後のリリースで修正予定: https://github.com/aws-amplify/amplify-js/pull/7401
    "Custom auth lambda trigger is not configured for the user pool.":
        "パスワードの入力は必須です",
    "Incorrect username or password.":
        "メールアドレスまたはパスワードが正しくありません",
    "User does not exist.": "ユーザーが存在しません ",
    "Password does not conform to policy: Password not long enough":
        "パスワードが短すぎます。",
    "Invalid session for the user, session is expired.": "セッションが無効です",
    "User is not confirmed.": "メールアドレスの確認が完了していません",
    "An account with the given email already exists.":
        "登録済みのメールアドレスは使用できません",
};
