import { ZenObservable } from "zen-observable-ts";
import { ModelIDInput } from "../../../API";
import { GraphQLGetItem, GraphQLQuery, GraphQLSubscribe } from "./graphql";
import { findGreedily } from "./misc";
import { NotFoundError } from "./not-found-error";
import { UpdateEvent } from "./update-event";

const sImpl = Symbol("Table#impl");
const sSubscription = Symbol("Table#subscription");

/**
 * 各 GraphQL API へのアクセスをカプセル化するクラスです。
 *
 * 以下の機能を提供します。
 *
 * - `GetItem` リゾルバを利用した取得操作 (`table.get(id, options)` メソッド)
 * - `Query` リゾルバを利用した取得操作 (`table.find(input, options)` メソッド)
 * - 変更監視 (`update` イベント)
 *
 * @see https://docs.aws.amazon.com/ja_jp/appsync/latest/devguide/resolver-mapping-template-reference-dynamodb.html
 */
export class Table<
    TRecord extends Table.RecordBase,
    TQueryMap extends Record<string, Table.QueryBase>,
    TPrimaryIndex extends keyof TQueryMap,
> extends EventTarget {
    /** 各種 GraphQL オペレーションの実装 */
    private readonly [sImpl]: Table.Impl<TRecord, TQueryMap, TPrimaryIndex>;
    /** 購読中の変更監視 */
    private readonly [sSubscription]: ZenObservable.Subscription;

    /**
     * 新しいインスタンスを初期化します。
     * @param impl 各種 GraphQL オペレーションの実装
     */
    constructor(impl: Table.Impl<TRecord, TQueryMap, TPrimaryIndex>) {
        super();
        this[sImpl] = impl;
        this[sSubscription] = impl.subscription.subscribe(
            (data) => {
                // 変化したレコードの ID を集めて、変更イベントを発火する。
                const ids = new Set(
                    Object.values(data)
                        .map((d) => d?.id)
                        .filter(isNotUndefined),
                );
                for (const id of ids) {
                    this.dispatchEvent(new UpdateEvent("update", id));
                }
            },
            (error) => {
                // TODO(mysticatea): どこかに通知する？ つなぎ直す？
                console.error(
                    "監視エラー(%s): %o",
                    this.constructor.name,
                    error.error,
                );
            },
        );
    }

    /**
     * ID を指定してレコードを取得します。
     * @param id 取得するレコードの ID
     * @param options オプション
     */
    async get(id: string, { signal }: Table.GetOptions = {}): Promise<TRecord> {
        const record = await this[sImpl].getItem.invoke(id, signal);
        if (record === undefined) {
            throw new NotFoundError(`"${id}" not found`);
        }
        return record;
    }

    /**
     * 検索条件を指定してレコードを取得します。
     *
     * 得られるレコードは 1 ページ分です。
     * 次のページを得るには、`nextToken` を利用して再検索してください。
     *
     * @param input 検索条件
     * @param options オプション
     */
    find(
        input?: Table.FindParams<TQueryMap, TPrimaryIndex>,
        options?: Table.FindOptions,
    ): Promise<Table.FindResult<TRecord>> {
        return this[sImpl].query.invoke(input, options?.signal);
    }

    /**
     * 検索条件を指定してレコードを取得します。
     *
     * 得られた結果が `nextToken` を含んでいた場合は自動的に次のページを検索し、
     * 全件取得します。
     *
     * @param input 検索条件
     * @param options オプション
     */
    async findGreedily(
        input?: Table.FindParams<TQueryMap, TPrimaryIndex>,
        options?: Table.FindOptions,
    ): Promise<readonly TRecord[]> {
        return findGreedily(input, options, this.find.bind(this));
    }

    /**
     * このテーブルを破棄します。
     */
    dispose(): void {
        this[sSubscription].unsubscribe();
    }

    /**
     * 更新イベントを購読します。
     * @param type イベント種別
     * @param callback イベントリスナ
     */
    addEventListener<T extends keyof Table.EventMap>(
        type: T,
        callback: (event: Table.EventMap[T]) => void,
    ): void;

    /**
     * イベントを購読します。
     * @param type イベント種別
     * @param callback イベントリスナ
     */
    addEventListener(type: string, callback: EventListener): void;

    addEventListener(type: string, callback: EventListener): void {
        super.addEventListener(type, callback);
    }

    /**
     * 更新イベントを購読解除します。
     * @param type イベント種別
     * @param callback イベントリスナ
     */
    removeEventListener<T extends keyof Table.EventMap>(
        type: T,
        callback: (event: Table.EventMap[T]) => void,
    ): void;

    /**
     * イベントを購読解除します。
     * @param type イベント種別
     * @param callback イベントリスナ
     */
    removeEventListener(type: string, callback: EventListener): void;

    removeEventListener(type: string, callback: EventListener): void {
        super.removeEventListener(type, callback);
    }
}
export namespace Table {
    /**
     * 実装。
     */
    export type Impl<
        TRecord extends RecordBase,
        TQueryMap extends Record<string, QueryBase>,
        TPrimaryIndex extends keyof TQueryMap,
    > = {
        getItem: GraphQLGetItem<any, any, TRecord>; // eslint-disable-line @typescript-eslint/no-explicit-any
        query: GraphQLQuery<
            { [P in keyof TQueryMap]: [TQueryMap[P], any] }, // eslint-disable-line @typescript-eslint/no-explicit-any
            TPrimaryIndex,
            TRecord
        >;
        subscription: GraphQLSubscribe<SubscriptionOutput>;
    };

    /**
     * レコードの基底型。
     *
     * `id` フィールドが必要です。
     */
    export type RecordBase = {
        /** ID。 */
        id: string;
    };

    /**
     * 検索条件の基底型。
     *
     * `id` フィールドで検索できる必要があります。
     */
    export type QueryBase = {
        filter?: FilterBase | null | undefined;
        limit?: number | null | undefined;
        nextToken?: string | null | undefined;
    };

    /**
     * 検索条件 (filter) の基底型。
     *
     * `id` フィールドで検索できる必要があります。
     */
    export type FilterBase = {
        id?: ModelIDInput | null | undefined;
        or?: readonly (FilterBase | null | undefined)[] | null | undefined;
    };

    /**
     * 購読する変更通知で受け取るオブジェクトの型。
     *
     * `id` フィールドが必要です。
     */
    export type SubscriptionOutput = {
        [key: string]: { id: string } | null | undefined;
    };

    /**
     * イベント名とイベントデータの型のマッピング。
     */
    export type EventMap = {
        update: UpdateEvent;
    };

    /**
     * 検索条件
     */
    export type FindParams<
        TQueryMap extends Record<string, QueryBase>,
        TPrimaryIndex extends keyof TQueryMap,
    > =
        | ({ index?: undefined } & TQueryMap[TPrimaryIndex])
        | {
              [P in keyof TQueryMap]: { index: P } & TQueryMap[P];
          }[keyof TQueryMap];

    /**
     * `get` メソッドのオプション。
     */
    export type GetOptions = {
        /** 中止シグナル */
        signal?: AbortSignal;
    };

    /**
     * `find` メソッドのオプション。
     */
    export type FindOptions = {
        /** 中止シグナル */
        signal?: AbortSignal;
    };

    /**
     * 検索結果。
     *
     * レコードの配列と次ページ取得のためのトークンがペアになっています。
     */
    export type FindResult<T> = {
        items: readonly T[];
        nextToken: string | undefined;
    };
}

/**
 * 指定した値が `undefined` 以外ならば `true`。
 * @param x 値
 */
function isNotUndefined<T>(x: T): x is Exclude<T, undefined> {
    return x !== undefined;
}
