import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";

/**
 * GraphQL API を叩きます。
 *
 * API がエラーを返した場合は例外をスローします。
 *
 * `signal`を渡すことで、API 実行を中止することができます。
 * 詳細は DOM 標準である `AbortController` の使い方を参照してください。
 *
 * @param query GraphQL クエリ文字列
 * @param input GraphQL クエリの変数に割り当てる値のマップ
 * @param signal 中止シグナル
 * @see https://developer.mozilla.org/en-US/docs/Web/API/AbortController
 */
export async function invokeGraphQL(
    query: string,
    input: Record<string, unknown> | undefined,
    signal: AbortSignal | undefined,
): Promise<unknown> {
    if (signal?.aborted) {
        throw new DOMException("aborted", "AbortError");
    }

    // API を叩く
    const operation = graphqlOperation(query, input);
    const promise = API.graphql(operation) as Promise<GraphQLResult<unknown>>;
    const onAbort = () => API.cancel(promise);
    signal?.addEventListener("abort", onAbort);

    // 結果を待機する
    let result: GraphQLResult<unknown>;
    try {
        result = await promise;
    } catch (error: unknown) {
        // 中止例外を DOM 標準に合わせる
        if (API.isCancel(error)) {
            throw new DOMException("aborted", "AbortError");
        }
        throw unwrapErrors(error) ?? error;
    } finally {
        signal?.removeEventListener("abort", onAbort);
    }

    // エラーを処理する
    const error = unwrapErrors(result as Record<string, unknown>);
    /* istanbul ignore next */
    if (error != null) {
        throw error;
    }
    /* istanbul ignore next */
    if (result.data == null) {
        throw new DOMException("aborted", "AbortError");
    }

    // Done!
    return result.data;
}

/**
 * 結果オブジェクトからエラーを取り出します。
 *
 * GraphQL の結果オブジェクトは `obj.errors` プロパティにエラーを格納します。
 * Amplify の結果オブジェクトは `obj.errors` プロパティにエラーを格納します。
 * エラー情報が Error オブジェクトではありません。
 *
 * 結果として `obj.errors[0].errors[0]` のような深い場所に実際のエラー情報が存在
 * し、かつ Error オブジェクトではない場合があり、これを取り出すのがこの関数の目
 * 的です。
 *
 * @param x 対象オブジェクト
 */
export function unwrapErrors(x: unknown): Error | undefined {
    if (x instanceof Error) {
        return x;
    }
    if (typeof x === "object" && x !== null) {
        const obj = x as Record<string, unknown>;
        if (typeof obj.message === "string") {
            // これはエラー情報だけど、Error オブジェクトではなかったので、移す
            const error = new Error(obj.message);
            Object.assign(error, obj);
            return error;
        }
        if (Array.isArray(obj.errors)) {
            return unwrapErrors(obj.errors[0]);
        }
    }
    return undefined;
}
