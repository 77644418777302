import {
    GetNodeQuery,
    ListNodesByAccountQuery,
    ListNodesByAccountQueryVariables,
    ListNodesByParentIdQuery,
    ListNodesByParentIdQueryVariables,
    ListNodesQuery,
    ListNodesQueryVariables,
    OnCreateNodeSubscription,
    OnDeleteNodeSubscription,
    OnUpdateNodeSubscription,
} from "../../API";
import {
    getNode,
    listNodes,
    listNodesByAccount,
    listNodesByParentId,
} from "../../graphql/queries";
import {
    onCreateNode,
    onDeleteNode,
    onUpdateNode,
} from "../../graphql/subscriptions";
import { Cache } from "./lib/cache";
import { CachedTable } from "./lib/cached-table";
import { GraphQLGetItem, GraphQLQuery, GraphQLSubscribe } from "./lib/graphql";

export type Node = Exclude<GetNodeQuery["getNode"], null | undefined>;

export class NodeTable extends CachedTable<
    // レコードの型
    Node,
    // インデックスのリスト
    {
        listNodes: ListNodesQueryVariables;
        listNodesByParentId: ListNodesByParentIdQueryVariables;
        listNodesByAccount: ListNodesByAccountQueryVariables;
    },
    // プライマリ・インデックス
    "listNodes"
> {
    constructor(cache: Cache) {
        super(cache, {
            getItem: new GraphQLGetItem<GetNodeQuery, "getNode", Node>(
                getNode,
                "getNode",
                convertItem,
            ),

            query: new GraphQLQuery<
                // インデックスのリスト
                {
                    listNodes: [ListNodesQueryVariables, ListNodesQuery];
                    listNodesByParentId: [
                        ListNodesByParentIdQueryVariables,
                        ListNodesByParentIdQuery,
                    ];
                    listNodesByAccount: [
                        ListNodesByAccountQueryVariables,
                        ListNodesByAccountQuery,
                    ];
                },
                // プライマリ・インデックス
                "listNodes",
                Node
            >(
                { listNodes, listNodesByParentId, listNodesByAccount },
                "listNodes",
                convertItem,
            ),

            subscription: new GraphQLSubscribe<
                | OnCreateNodeSubscription
                | OnDeleteNodeSubscription
                | OnUpdateNodeSubscription
            >([onCreateNode, onDeleteNode, onUpdateNode]),
        });
    }
}

function convertItem(item: Node): Node {
    return item;
}
