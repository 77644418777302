import {
    GetOldDatumQuery,
    ListOldDataByDatumIdQuery,
    ListOldDataByDatumIdQueryVariables,
    ListOldDataQuery,
    ListOldDataQueryVariables,
    OnCreateOldDatumSubscription,
    OnDeleteOldDatumSubscription,
    OnUpdateOldDatumSubscription,
} from "../../API";
import {
    getOldDatum,
    listOldData,
    listOldDataByDatumId,
} from "../../graphql/queries";
import {
    onDeleteOldDatum,
    onUpdateOldDatum,
} from "../../graphql/subscriptions";
import { Cache } from "./lib/cache";
import { CachedTable } from "./lib/cached-table";
import { GraphQLGetItem, GraphQLQuery, GraphQLSubscribe } from "./lib/graphql";

export type OldDatum = Exclude<
    GetOldDatumQuery["getOldDatum"],
    null | undefined
>;

export class OldDatumTable extends CachedTable<
    // レコードの型
    OldDatum,
    // インデックスのリスト
    {
        listOldData: ListOldDataQueryVariables;
        listOldDataByDatumId: ListOldDataByDatumIdQueryVariables;
    },
    // プライマリ・インデックス
    "listOldData"
> {
    constructor(cache: Cache) {
        super(cache, {
            getItem: new GraphQLGetItem<
                GetOldDatumQuery,
                "getOldDatum",
                OldDatum
            >(getOldDatum, "getOldDatum", convertItem),

            query: new GraphQLQuery<
                // インデックスのリスト
                {
                    listOldData: [ListOldDataQueryVariables, ListOldDataQuery];
                    listOldDataByDatumId: [
                        ListOldDataByDatumIdQueryVariables,
                        ListOldDataByDatumIdQuery,
                    ];
                },
                // プライマリ・インデックス
                "listOldData",
                OldDatum
            >(
                { listOldData, listOldDataByDatumId },
                "listOldData",
                convertItem,
            ),

            subscription: new GraphQLSubscribe<
                | OnCreateOldDatumSubscription
                | OnDeleteOldDatumSubscription
                | OnUpdateOldDatumSubscription
            >([onDeleteOldDatum, onUpdateOldDatum]),
        });
    }
}

function convertItem(item: OldDatum): OldDatum {
    return item;
}
