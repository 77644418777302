/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-process-env */
import { Analytics } from "@aws-amplify/analytics";
import { Amplify, I18n } from "@aws-amplify/core";
import React from "react";
import ReactDom from "react-dom";
import awsconfig from "./aws-exports";
import { App } from "./view/app";
import JA from "./view/lang/ja";

/**
 * Amplify の設定をする
 */
Amplify.configure({
    ...awsconfig,
    Auth: {
        mandatorySignIn: false,
    },
});

if (process.env.NODE_ENV === "production") {
    Analytics.autoTrack("pageView", {
        enable: true,
        getUrl() {
            return location.origin + location.pathname;
        },
        type: "SPA",
    });

    Analytics.autoTrack("event", {
        enable: true,
        events: ["click"],
        selectorPrefix: "data-amplify-analytics-",
    });
}

I18n.putVocabulariesForLanguage("ja", JA);
// Amplify.Logger.LOG_LEVEL = "DEBUG";

/**
 * 描画先の `<div>` 要素
 */
const appElement = document.createElement("div");
appElement.id = "app";
document.body.appendChild(appElement);
document.body.id = "root";

/**
 * 描画する
 */
ReactDom.render(React.createElement(App), appElement);
