import { Cache } from "./cache";
import { GraphQLQuery } from "./graphql";
import { findGreedily } from "./misc";
import { NotFoundError } from "./not-found-error";
import { Table } from "./table";

const sCache = Symbol("CachedTable#cache");
const sQueryRevision = Symbol("CachedTable#queryRevision");
const sRecordRevision = Symbol("CachedTable#recordRevision");
const sListIds = Symbol("CachedTable#listIds");
const sRecordCacheKey = Symbol("CachedTable#recordCacheKey");
const sFindImpl = Symbol("CachedTable#findImpl");

/**
 * 一度にキャッシュできる ID 数の上限
 * DynamoDB のフィルター文字列制約 4KB と
 * DynamoDB のデフォルトの ID 文字列長 36 文字を基に算出
 */
const CACHE_IDS_LIMIT = 150;

/**
 * 各 GraphQL API へのアクセスをカプセル化するクラスです。
 *
 * このクラスは、{@link Table} クラスの機能に加えて、キャッシュ機能を提供します。
 */
export class CachedTable<
    TRecord extends Table.RecordBase,
    TQueryMap extends Record<string, Table.QueryBase>,
    TPrimaryIndex extends keyof TQueryMap,
> extends Table<TRecord, TQueryMap, TPrimaryIndex> {
    /** キャッシュ */
    private [sCache]: Cache;
    /** クエリキャッシュクリア回数 (0..0x7fffffff をループ) */
    private [sQueryRevision] = 0;
    /** レコードキャッシュクリア回数 (0..0x7fffffff をループ) */
    private [sRecordRevision] = 0;
    /** `id` だけを取得する `Query` リゾルバへの操作 */
    private readonly [sListIds]: GraphQLQuery<
        { [P in keyof TQueryMap]: [TQueryMap[P], any] }, // eslint-disable-line @typescript-eslint/no-explicit-any
        TPrimaryIndex,
        string
    >;

    /**
     * 新しいインスタンスを初期化します。
     * @param cache キャッシュ
     * @param impl 各種 GraphQL オペレーションの実装
     */
    constructor(
        cache: Cache,
        impl: Table.Impl<TRecord, TQueryMap, TPrimaryIndex>,
    ) {
        super(impl);
        this[sCache] = cache;
        this[sListIds] = createListIdsOperation(impl.query);
        this.addEventListener("update", (event) => {
            this.clearCache(event.id);
        });
    }

    /**
     * ID を指定してレコードを取得します。
     * @param id 取得するレコードの ID
     * @param options オプション
     */
    get(id: string, options: Table.GetOptions = {}): Promise<TRecord> {
        const key = this[sRecordCacheKey](id);
        return this[sCache]
            .flyweightGet(key, () => super.get(id, options))
            .catch((error: unknown) => {
                // キャッシュが中止され、このオペレーションが中止されていない場合、
                // 改めて取り直す
                if (
                    error instanceof Error &&
                    error.name === "AbortError" &&
                    !options.signal?.aborted
                ) {
                    return this.get(id, options);
                }
                throw error;
            });
    }

    /**
     * 検索条件を指定してレコードを取得します。
     *
     * 得られるレコードは 1 ページ分です。
     * 次のページを得るには、`nextToken` を利用して再検索してください。
     *
     * @param input 検索条件
     * @param options オプション
     */
    find(
        input?: Table.FindParams<TQueryMap, TPrimaryIndex>,
        options: Table.FindOptions = {},
    ): Promise<Table.FindResult<TRecord>> {
        const inputStr = JSON.stringify(input ?? {});
        const key = `${this.constructor.name}:query#${this[sQueryRevision]}:${inputStr}`;
        return this[sCache]
            .flyweightGet(key, () => this[sFindImpl](input, options))
            .catch((error) => {
                // キャッシュが中止され、このオペレーションが中止されていない場合、
                // 改めて取り直す
                if (error.name === "AbortError" && !options.signal?.aborted) {
                    return this.find(input, options);
                }
                throw error;
            });
    }

    /**
     * 検索条件を指定してレコードを取得します。
     *
     * 得られた結果が `nextToken` を含んでいた場合は自動的に次のページを検索し、
     * 全件取得します。
     *
     * @param input 検索条件
     * @param options オプション
     */
    async findGreedily(
        input?: Table.FindParams<TQueryMap, TPrimaryIndex>,
        options: Table.FindOptions = {},
    ): Promise<readonly TRecord[]> {
        const inputStr = JSON.stringify(input ?? {});
        const key = `${this.constructor.name}:query-g#${this[sQueryRevision]}:${inputStr}`;
        return this[sCache]
            .flyweightGet(key, () => super.findGreedily(input, options))
            .catch((error) => {
                // キャッシュが中止され、このオペレーションが中止されていない場合、
                // 改めて取り直す
                if (error.name === "AbortError" && !options.signal?.aborted) {
                    return this.findGreedily(input, options);
                }
                throw error;
            });
    }

    /**
     * キャッシュを破棄します。
     * `id`を指定した場合、該当 ID 以外のレコードのキャッシュは保持されます。
     * @param id キャッシュを破棄するレコードの ID
     */
    clearCache(id?: string): void {
        this[sQueryRevision] = (this[sQueryRevision] + 1) & 0x7fffffff;
        if (typeof id === "string") {
            this[sCache].flyweightRemove(this[sRecordCacheKey](id));
        } else {
            this[sRecordRevision] = (this[sRecordRevision] + 1) & 0x7fffffff;
        }
    }

    /**
     * 指定 ID のためのレコード・キャッシュのキーを作成します。
     * @param id ID
     */
    private [sRecordCacheKey](id: string): string {
        return `${this.constructor.name}:record#${this[sRecordRevision]}:${id}`;
    }

    /**
     * 検索処理を実施します。
     *
     * これは以下の手順です。
     *
     * 1. 検索条件に合致するレコードの ID をサーバーから取得する。
     * 1. 各 ID についてキャッシュされているか確認し、キャッシュになかったものは
     *    まとめて取得してキャッシュする。
     * 1. 各 ID について、キャッシュから取り出して返す。
     *
     * @param input 検索条件
     * @param options オプション
     */
    private async [sFindImpl](
        input: Table.FindParams<TQueryMap, TPrimaryIndex> | undefined,
        options: Table.FindOptions,
    ): Promise<Table.FindResult<TRecord>> {
        // 検索に合致したれこーどの ID リストを取得する
        const { items: ids, nextToken } = await this[sListIds].invoke(
            input,
            options.signal,
        );

        // 各 ID に合致するレコードをキャッシュから取得する
        // キャッシュに無いものは`conditions` に追加して、後でまとめて取ってくる
        const conditions: Exclude<Table.QueryBase["filter"], undefined>[] = [];
        const deferred = createDeferred<readonly TRecord[]>();
        const itemsPromise = Promise.all<TRecord>(
            ids.map((id) =>
                this[sCache].flyweightGet(
                    this[sRecordCacheKey](id),
                    async () => {
                        // キャッシュになかったので条件を追加
                        conditions.push({ id: { eq: id } });
                        // まとめて取得したらその中から検索して返す
                        const record = (await deferred.promise).find(
                            (record) => record.id === id,
                        );
                        if (record === undefined) {
                            // 必ず見つかるはずだけど...
                            // TODO(mysticatea): 取り直すべき？
                            throw new NotFoundError(`"${id}" not found`);
                        }
                        return record;
                    },
                ),
            ),
        );

        // キャッシュになかったものを取ってくる
        if (conditions.length === 0) {
            deferred.resolve([]);
        } else {
            // TODO: キャッシュする ID が 150 件を超える場合は分割してクエリーを発行する
            const cacheData = await Promise.all(
                arrayChunk(conditions, CACHE_IDS_LIMIT).map(
                    async (conditions: Table.QueryBase["filter"][]) => {
                        const query: Table.QueryBase = {
                            ...input,
                            limit: CACHE_IDS_LIMIT,
                            filter: { or: conditions },
                        };
                        const record = (await findGreedily(
                            query as TQueryMap[TPrimaryIndex],
                            options,
                            super.find.bind(this),
                        ).catch(deferred.reject)) as readonly TRecord[];
                        return record;
                    },
                ),
            );
            deferred.resolve(cacheData.flat());
        }

        // Done.
        return { items: await itemsPromise, nextToken };
    }
}

function createListIdsOperation<
    TRecord,
    TQueryMap extends Record<
        string,
        [GraphQLQuery.InputBase, GraphQLQuery.OutputBase]
    >,
    TPrimaryIndex extends keyof TQueryMap,
>(
    original: GraphQLQuery<TQueryMap, TPrimaryIndex, TRecord>,
): GraphQLQuery<TQueryMap, TPrimaryIndex, string> {
    const queries = Object.entries(original.querys)
        .map(([key, query]) => {
            const startMatch = /\bitems\s*\{/u.exec(query);
            if (!startMatch) {
                throw new Error(
                    'The query operation must include "items {" string.',
                );
            }
            const startIndex = startMatch.index;
            const endIndex = findRightBrace(
                query,
                startIndex + startMatch[0].length,
            );
            const prefix = query.slice(0, startIndex);
            const suffix = query.slice(endIndex + 1);
            return [key, `${prefix}items { id }\n${suffix}`];
        })
        .reduce((map, [key, query]) => {
            map[key as keyof TQueryMap] = query;
            return map;
        }, {} as Record<keyof TQueryMap, string>);
    return new GraphQLQuery<TQueryMap, TPrimaryIndex, string>(
        queries,
        original.primaryIndex,
        (item) => String((item as { id: unknown }).id),
    );
}

function findRightBrace(s: string, startIndex: number): number {
    let nest = 0;
    for (let i = startIndex; i < s.length; ++i) {
        const c = s.charCodeAt(i);
        if (c === 0x7b /* { */) {
            nest += 1;
        } else if (c === 0x7d /* } */) {
            nest -= 1;
            if (nest < 0) {
                return i;
            }
        }
    }

    throw new Error(`Syntax Error: Unexpected EOF\n${s}`);
}

function createDeferred<T>(): {
    promise: Promise<T>;
    resolve: (value: T) => void;
    reject: (error: unknown) => void;
} {
    let resolve: (value: T) => void, reject: (error: unknown) => void;
    const promise = new Promise<T>((resolve0, reject0) => {
        resolve = resolve0;
        reject = reject0;
    });
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return { promise, resolve: resolve!, reject: reject! };
}

/**
 * 配列を指定した個数ずつに分割する
 * @param array array 分割したい配列
 * @param size 分割単位数
 * @returns array
 */
function arrayChunk<T>(array: readonly T[], size = 1): T[][] {
    return array.reduce<T[][]>(
        (acc, _value, index) =>
            index % size ? acc : [...acc, array.slice(index, index + size)],
        [],
    );
}
