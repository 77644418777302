export class Cache {
    private readonly _capacity = 0xffff;
    private readonly _cache = new Map<string, Promise<unknown>>();
    private readonly _stats = {
        hit: 0,
        miss: 0,
        get total(): number {
            return this.hit + this.miss;
        },
    };

    /**
     * キーに対応する値がキャッシュされていればそれを返し、なければ作成します。
     * @param key キー
     * @param create キーに対応する値が無かった場合に呼ばれる生成関数
     */
    flyweightGet<T>(key: string, create: () => Promise<T>): Promise<T> {
        let value = this._cache.get(key) as Promise<T> | undefined;

        if (value !== undefined) {
            this._collectStats("hit", key);
            this._moveToLast(key, value);
        } else {
            this._collectStats("miss", key);
            value = create().catch((error: unknown) => {
                // キャッシュした Promise が中止された場合、キャッシュから除去する。
                if (
                    error instanceof Error &&
                    error.name === "AbortError" &&
                    this._cache.get(key) === value
                ) {
                    this._cache.delete(key);
                }
                throw error;
            });
            this._cache.set(key, value);
            this._removeOldEntries();
        }

        return value;
    }

    /**
     * キーに対応する値を削除します。
     * @param key キー
     */
    flyweightRemove(key: string): void {
        this._cache.delete(key);
    }

    /**
     * 統計を取ってログに出します。
     * @param type 種別
     * @param cacheKey キー
     */
    private _collectStats(type: "hit" | "miss", cacheKey: string): void {
        this._stats[type] += 1;
        // eslint-disable-next-line no-console
        console.debug(
            "Cache %s (key: %s) (totalHitRate: %s%)",
            type,
            cacheKey,
            ((100 * this._stats.hit) / this._stats.total).toFixed(1),
        );
    }

    /**
     * 指定したエントリを末尾に移動します。
     * (溢れたとき、先頭から (使われていないものから) 削除するため)
     * @param key 移動するエントリのキー
     * @param value 移動するエントリの値
     */
    private _moveToLast<T>(key: string, value: Promise<T>): void {
        this._cache.delete(key);
        this._cache.set(key, value);
    }

    /**
     * 溢れた分だけ先頭から (使われていないものから) 削除します。
     */
    private _removeOldEntries(): void {
        if (this._cache.size <= this._capacity) {
            return;
        }

        // 溢れたので古いものから 12.5% 削除する
        const end = this._capacity >> 3;
        let i = 0;
        for (const key of this._cache.keys()) {
            this._cache.delete(key);
            if (++i >= end) {
                break;
            }
        }
    }
}
