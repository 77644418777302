import {
    GetTenantQuery,
    ListTenantsQuery,
    ListTenantsQueryVariables,
    OnCreateTenantSubscription,
    OnDeleteTenantSubscription,
    OnUpdateTenantSubscription,
} from "../../API";
import { getTenant, listTenants } from "../../graphql/queries";
import {
    onCreateTenant,
    onDeleteTenant,
    onUpdateTenant,
} from "../../graphql/subscriptions";
import { Cache } from "./lib/cache";
import { CachedTable } from "./lib/cached-table";
import { GraphQLGetItem, GraphQLQuery, GraphQLSubscribe } from "./lib/graphql";

export type Tenant = Exclude<GetTenantQuery["getTenant"], null | undefined>;

export class TenantTable extends CachedTable<
    // レコードの型
    Tenant,
    // インデックスのリスト
    {
        listTenants: ListTenantsQueryVariables;
    },
    // プライマリ・インデックス
    "listTenants"
> {
    constructor(cache: Cache) {
        super(cache, {
            getItem: new GraphQLGetItem<GetTenantQuery, "getTenant", Tenant>(
                getTenant,
                "getTenant",
                convertItem,
            ),

            query: new GraphQLQuery<
                // インデックスのリスト
                {
                    listTenants: [ListTenantsQueryVariables, ListTenantsQuery];
                },
                // プライマリ・インデックス
                "listTenants",
                Tenant
            >({ listTenants }, "listTenants", convertItem),

            subscription: new GraphQLSubscribe<
                | OnCreateTenantSubscription
                | OnDeleteTenantSubscription
                | OnUpdateTenantSubscription
            >([onCreateTenant, onDeleteTenant, onUpdateTenant]),
        });
    }
}

function convertItem(item: Tenant): Tenant {
    return item;
}
