import {
    GetDatumTypeQuery,
    ListDatumTypesByNodeTypeIdQuery,
    ListDatumTypesByNodeTypeIdQueryVariables,
    ListDatumTypesQuery,
    ListDatumTypesQueryVariables,
    OnCreateDatumTypeSubscription,
    OnDeleteDatumTypeSubscription,
    OnUpdateDatumTypeSubscription,
} from "../../API";
import {
    getDatumType,
    listDatumTypes,
    listDatumTypesByNodeTypeId,
} from "../../graphql/queries";
import {
    onCreateDatumType,
    onDeleteDatumType,
    onUpdateDatumType,
} from "../../graphql/subscriptions";
import { Cache } from "./lib/cache";
import { CachedTable } from "./lib/cached-table";
import { GraphQLGetItem, GraphQLQuery, GraphQLSubscribe } from "./lib/graphql";

export type DatumType = Exclude<
    GetDatumTypeQuery["getDatumType"],
    null | undefined
>;

export class DatumTypeTable extends CachedTable<
    // レコードの型
    DatumType,
    // インデックスのリスト
    {
        listDatumTypes: ListDatumTypesQueryVariables;
        listDatumTypesByNodeTypeId: ListDatumTypesByNodeTypeIdQueryVariables;
    },
    // プライマリ・インデックス
    "listDatumTypes"
> {
    constructor(cache: Cache) {
        super(cache, {
            getItem: new GraphQLGetItem<
                GetDatumTypeQuery,
                "getDatumType",
                DatumType
            >(getDatumType, "getDatumType", convertItem),

            query: new GraphQLQuery<
                // インデックスのリスト
                {
                    listDatumTypes: [
                        ListDatumTypesQueryVariables,
                        ListDatumTypesQuery,
                    ];
                    listDatumTypesByNodeTypeId: [
                        ListDatumTypesByNodeTypeIdQueryVariables,
                        ListDatumTypesByNodeTypeIdQuery,
                    ];
                },
                // プライマリ・インデックス
                "listDatumTypes",
                DatumType
            >(
                { listDatumTypes, listDatumTypesByNodeTypeId },
                "listDatumTypes",
                convertItem,
            ),

            subscription: new GraphQLSubscribe<
                | OnCreateDatumTypeSubscription
                | OnDeleteDatumTypeSubscription
                | OnUpdateDatumTypeSubscription
            >([onCreateDatumType, onDeleteDatumType, onUpdateDatumType]),
        });
    }
}

function convertItem(item: DatumType): DatumType {
    return item;
}
