import {
    GetEccMenuQuery,
    ListEccMenusByAccountIdQuery,
    ListEccMenusByAccountIdQueryVariables,
    ListEccMenusQuery,
    ListEccMenusQueryVariables,
    OnCreateEccMenuSubscription,
    OnDeleteEccMenuSubscription,
    OnUpdateEccMenuSubscription,
} from "../../API";
import {
    getEccMenu,
    listEccMenus,
    listEccMenusByAccountId,
} from "../../graphql/queries";
import {
    onCreateEccMenu,
    onDeleteEccMenu,
    onUpdateEccMenu,
} from "../../graphql/subscriptions";
import { Cache } from "./lib/cache";
import { CachedTable } from "./lib/cached-table";
import { GraphQLGetItem, GraphQLQuery, GraphQLSubscribe } from "./lib/graphql";

export type EccMenu = Exclude<GetEccMenuQuery["getEccMenu"], null | undefined>;

export class EccMenuTable extends CachedTable<
    // レコードの型
    EccMenu,
    // インデックスのリスト
    {
        listEccMenus: ListEccMenusQueryVariables;
        listEccMenusByAccountId: ListEccMenusByAccountIdQueryVariables;
    },
    // プライマリ・インデックス
    "listEccMenus"
> {
    constructor(cache: Cache) {
        super(cache, {
            getItem: new GraphQLGetItem<GetEccMenuQuery, "getEccMenu", EccMenu>(
                getEccMenu,
                "getEccMenu",
                convertItem,
            ),

            query: new GraphQLQuery<
                // インデックスのリスト
                {
                    listEccMenus: [
                        ListEccMenusQueryVariables,
                        ListEccMenusQuery,
                    ];
                    listEccMenusByAccountId: [
                        ListEccMenusByAccountIdQueryVariables,
                        ListEccMenusByAccountIdQuery,
                    ];
                },
                // プライマリ・インデックス
                "listEccMenus",
                EccMenu
            >(
                { listEccMenus, listEccMenusByAccountId },
                "listEccMenus",
                convertItem,
            ),

            subscription: new GraphQLSubscribe<
                | OnCreateEccMenuSubscription
                | OnDeleteEccMenuSubscription
                | OnUpdateEccMenuSubscription
            >([onCreateEccMenu, onDeleteEccMenu, onUpdateEccMenu]),
        });
    }
}

function convertItem(item: EccMenu): EccMenu {
    return item;
}
