import {
    GetOldDatumDailyQuery,
    ListOldDataDailyByDatumIdQuery,
    ListOldDataDailyByDatumIdQueryVariables,
    ListOldDataQuery,
    ListOldDataQueryVariables,
    OnCreateOldDatumDailySubscription,
    OnDeleteOldDatumDailySubscription,
    OnUpdateOldDatumDailySubscription,
} from "../../API";
import {
    getOldDatumDaily,
    listOldData,
    listOldDataDailyByDatumId,
} from "../../graphql/queries";
import {
    onDeleteOldDatumDaily,
    onUpdateOldDatumDaily,
} from "../../graphql/subscriptions";
import { Cache } from "./lib/cache";
import { CachedTable } from "./lib/cached-table";
import { GraphQLGetItem, GraphQLQuery, GraphQLSubscribe } from "./lib/graphql";

export type OldDatumDaily = Exclude<
    GetOldDatumDailyQuery["getOldDatumDaily"],
    null | undefined
>;

export class OldDatumDailyTable extends CachedTable<
    // レコードの型
    OldDatumDaily,
    // インデックスのリスト
    {
        listOldData: ListOldDataQueryVariables;
        listOldDataDailyByDatumId: ListOldDataDailyByDatumIdQueryVariables;
    },
    // プライマリ・インデックス
    "listOldData"
> {
    constructor(cache: Cache) {
        super(cache, {
            getItem: new GraphQLGetItem<
                GetOldDatumDailyQuery,
                "getOldDatumDaily",
                OldDatumDaily
            >(getOldDatumDaily, "getOldDatumDaily", convertItem),

            query: new GraphQLQuery<
                // インデックスのリスト
                {
                    listOldData: [ListOldDataQueryVariables, ListOldDataQuery];
                    listOldDataDailyByDatumId: [
                        ListOldDataDailyByDatumIdQueryVariables,
                        ListOldDataDailyByDatumIdQuery,
                    ];
                },
                // プライマリ・インデックス
                "listOldData",
                OldDatumDaily
            >(
                { listOldData, listOldDataDailyByDatumId },
                "listOldData",
                convertItem,
            ),

            subscription: new GraphQLSubscribe<
                | OnCreateOldDatumDailySubscription
                | OnDeleteOldDatumDailySubscription
                | OnUpdateOldDatumDailySubscription
            >([onDeleteOldDatumDaily, onUpdateOldDatumDaily]),
        });
    }
}

function convertItem(item: OldDatumDaily): OldDatumDaily {
    return item;
}
