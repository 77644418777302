import { invokeGraphQL } from "./misc";

/**
 * GraphQL API の `GetItem` リゾルバを叩く処理をカプセル化するクラスです。
 * @see https://docs.aws.amazon.com/ja_jp/appsync/latest/devguide/resolver-mapping-template-reference-dynamodb.html#aws-appsync-resolver-mapping-template-reference-dynamodb-getitem
 */
export class GraphQLGetItem<
    TOutput,
    TDataField extends keyof TOutput,
    TRecord,
> {
    /** GraphQL クエリ文字列 */
    readonly query: string;
    /** 結果値が格納されるプロパティのプロパティ名 */
    readonly dataField: TDataField;
    /** 結果値を変換する関数 */
    readonly convertItem: GraphQLGetItem.ConvertFunction<
        TOutput,
        TDataField,
        TRecord
    >;

    constructor(
        query: string,
        dataField: TDataField,
        convertItem: GraphQLGetItem.ConvertFunction<
            TOutput,
            TDataField,
            TRecord
        >,
    ) {
        this.query = query;
        this.dataField = dataField;
        this.convertItem = convertItem;
    }

    /**
     * GraphQL API を叩いて、指定した ID のレコードを取得します。
     * @param id 取得するレコードの ID 値。
     * @param signal 中止シグナル
     */
    async invoke(
        id: string,
        signal: AbortSignal | undefined,
    ): Promise<TRecord | undefined> {
        const { convertItem, dataField, query } = this;
        const output = (await invokeGraphQL(query, { id }, signal)) as TOutput;
        const data = output[dataField];
        if (data == null) {
            return undefined;
        }
        return convertItem(
            data as Exclude<TOutput[TDataField], null | undefined>,
        );
    }
}
export namespace GraphQLGetItem {
    export type ConvertFunction<
        TOutput,
        TDataField extends keyof TOutput,
        TRecord,
    > = (item: Exclude<TOutput[TDataField], null | undefined>) => TRecord;
}
